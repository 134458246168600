.trendingSection {
  // height: 193px;
  padding: 0px 20px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #ebebeb;
  .trendingSectionTitle {
    font-size: 20px;
    font-weight: 700;
    padding-top: 29px;
    padding-bottom: 20px;
  }
  .trendingItems {
    // height: 100px;
    width: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 13px;
  }

  .trendingItemsImg {
    aspect-ratio: 1/1;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
  }
  .trendingItemTitle {
    height: 30%;
    width: 100px;
    // padding-left: 25px;
    // background: rgba(0, 0, 0, 0.55);
    border-radius: 0px 0px 15px 15px;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    line-height: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    text-align: center;
    color: #212529;
    margin-top: 10px;
  }
}

.heroSection {
  padding: 0px 20px;
  .heroSectionTitle {
    font-size: 20px;
    font-weight: 700;
    padding-top: 29px;
    // padding-bottom: 20px;
  }
}

.container3 {
  transition: all 0.3s ease-in-out;
  margin-top: 40px;
  padding: 20px 12px;
  // height: 30vh;
  position: sticky;
  top: 0px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  .categoryHeader {
    font-size: 20px;
    font-weight: 700;
  }
  .pillWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 39px;
    // margin-right: ;
  }
}
.searchPill {
  border-radius: 35px;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  background-color: white;
  margin-bottom: 22px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    background-color: #4b2a91;
    color: white;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
  }
}
.selectedSearchPill {
  border-radius: 35px;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 22px;
  margin-right: 8px;
  background-color: #4b2a91;
  color: white;
  transition: all 0.3s ease-in-out;
}

.newcontainer3 {
  margin-top: 40px;
  padding: 0px 12px;
  // position: -webkit-sticky;
  // position: -moz-sticky;
  // position: -o-sticky;
  // position: -ms-sticky;
  position: sticky;
  top: 0px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  .categoryHeader {
    display: none;
  }
  .pillWrapper {
    display: flex;
    margin-top: 20px;
    overflow-x: scroll;
    // margin-right: ;
  }
}
