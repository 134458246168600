.mobileSidebarMenu {
  height: 100%;
  position: relative;

  & > .mobileNavbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 20px;
    height: 90px;
    border-bottom: 6px solid #4b2a91;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > :nth-child(1) > svg {
      height: 25px;
      width: 25px;
      font-weight: 700;
    }

    & > :nth-child(2) > img {
      height: 50px;
      width: 50px;
      rotate: 180deg;
    }

    & > :nth-child(3) > img {
      height: 25px;
      width: 25px;
      // rotate: 90deg;
    }

    & > :nth-child(1) {
      justify-content: flex-start;
    }
    & > :nth-child(3) {
      justify-content: flex-end;
    }
  }

  & > .options {
    padding: 30px 20px;

    & > a {
      text-decoration: none;
      padding: 1.1rem;
      color: inherit;
      display: flex;
      color: #15172a;
      font-weight: 600;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    & > .selectedOption {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }

  & > .mainBtns {
    padding: 10px 20px;
    margin-bottom: 10px;

    & > a {
      background: #4b2a91;
      border-radius: 35px;
      cursor: pointer;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 600;
      font-size: 1.3rem;
      text-decoration: none;
    }

    & > :nth-child(2) {
      margin-top: 10px;
      background: #212529;
    }
  }
}
