.mobileNavBar {
  display: none;
  position: fixed;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;
  border-bottom: 5px solid #6669b0;
  background: white;
  height: 76px;
  // border-bottom: 5px solid red;
  border-left-width: 0;
  border-right-width: 0;
  // -webkit-box-shadow: 0 5px 5px -5px #000000;
  // -moz-box-shadow: 0 5px 5px -5px #000000;
  // box-shadow: 0 5px 5px -5px #000000;
  border-image: linear-gradient(to right, #6669b0, #55c9ea) 1 stretch;
}

@media only screen and (max-width: 900.9px) {
  .mobileNavBar {
    display: flex;
  }

  }