.container {
  padding-left: 140px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 58px;
  // padding: 58px 140px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
  border-bottom: 0.5px solid #e7e7e7;
}
.header {
  font-weight: 600;
  font-size: 35px;
}
.breadcrumb {
  font-weight: 400;
  font-size: 22px;
  padding-top: 20px;
}
.profilePicture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  // background-color: lightgray;
  // margin-right: 20px;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }
}
.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.inputContainer {
  padding-top: 50px;
  .inputLabel {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 17px;
  }
  .inputStyle {
    width: 100%;
    height: 67px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    &::placeholder {
      font-weight: 700;
      font-size: 18px;
      color: #212529;
      opacity: 0.25;
    }
  }
}
.uploadStyle {
  height: 300px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.uploadText {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #e5e5e5;
}

.registraionBtn {
  margin-top: 40px;
  width: fit-content;
  font-weight: 700;
  font-size: 15px;
  color: white;
  background-color: #4b2a91;
  border-radius: 35px;
  padding: 22px 43px;
  &:hover {
    cursor: pointer;
    background-color: white;
    color: #4b2a91;
    font-weight: 700;
    border: 1px solid #4b2a91;
    transition: all 0.3s ease-in-out;
  }
}

.loadingBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-top: -20%;
  }
}

.prevLink {
  font-size: 22px;
  text-decoration: none;
  &:hover {
    // font-weight: 700;
    cursor: pointer;
    // color: #4B2A91;
    text-decoration: underline;
  }
}
