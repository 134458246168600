.sidebar {
    height: 100vh;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    width: 250px;

    @media screen and (max-width:1000px) {
        display: none;
    }
}

.thesideicon {
    @media screen and (max-width : 1000px) {
        margin-left: 10px;
        width: 25px;
    }
}


.theclose {
    // @media screen and (min-width : 1000px) {
    //     display: none;
    // }
    width: 25px;
    margin-left: auto;
}

.sidetext {
    font-size: 20px;
    font-weight: 700;
    margin-left: 5px;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width : 1000px) {
        font-size: 13px;
    }
}

.sideimg {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
}


.thebuttons {
    justify-content: center;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.logbtn {
    background: white;
    border: white;
    font-weight: 600;
}

.signbtn {
    background: black;
    border: white;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    padding: 10px;
}

.sidemenu {
    padding-left: 30px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 15px;
}

.thearrowright {
    width: 10px;
    margin-right: 6px;
}

.menutxt {
    font-weight: 700;
}

.buttonapps {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 20px;
}

.socialapps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 20px;
}

.theappsocial {
    width: 33%;
    height: 20px;
    margin-top: 20px;
}

.appdata {
    margin: 5px;
    border: none;
    background: black;
    color: white;
    font-weight: 700;
    border-radius: 3px;
    padding: 5px;
    font-size: 12px;
    width: 100px;
}

.theclosee {
    margin-left: auto;
    margin-right: 10px;
    @media screen and (min-width : 1000px) {
        display: none;
    }
}