.loading-wrapper {
  position: relative;
  z-index: 4;
  margin: auto;
  @keyframes heartbeat {
    0% {
      transform: scale(0.75);
    }
    20% {
      transform: scale(1);
    }
    40% {
      transform: scale(0.75);
    }
    60% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(0.75);
    }
  }

  .loading-img {
    animation: heartbeat 1.3s infinite;
  }
}
