.container { 
  padding: 0 130px;
}
.header{
    font-weight: 700;
    font-size: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.cardContainer{
    display: flex;
    overflow-x: scroll;
}
.card{
    background: #FFFFFF;
    border: 0.5px solid #E7E7E7;
    border-radius: 20px;
    width: 305px !important;
    height: 146px;
}

.listGrid{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    padding: 10px 30px;
    height: 45px;
    background-color: #FAFAFA;
    border-bottom: solid 0.5px #E7E7E7;
    font-size: 0.8rem;
    // font-size: 1.6vh;
// font-size: clamp(0.8rem, 0.8rem + 12 * (100vw - 400px) / 800, 0.9rem);
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
  
    }
    & > div{
        font-weight: 600;
       
    } 
}


.listGridMobile{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  
    font-size: 0.8rem;
    // font-size: 1.6vh;
// font-size: clamp(0.8rem, 0.8rem + 12 * (100vw - 400px) / 800, 0.9rem);
    & > div:not(:first-child){
        text-align: right;
        font-weight: 600;
        padding: 10px 30px;
        height: 45px;
        background-color: #FAFAFA;
        border-bottom: solid 0.5px #E7E7E7;
    }
    & > div{
        font-weight: 600;
        padding: 10px 30px;
        height: 45px;
        background-color: #FAFAFA;
        border-bottom: solid 0.5px #E7E7E7;
    } 
}



.listDataGrid{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    padding: 10px 30px;
    border-bottom: solid 0.5px #E7E7E7;
    height: 90px;
    .title{
        font-size: 0.9rem;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 0.65rem ;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 10% ;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 ;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.listDataGridMobile{
    display: grid ;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
   
    .title{
        font-size: 0.9rem;
        font-weight: 700;
        color: #18191D;
    }
    .subtitle{
        font-size: 0.65rem ;
        font-weight: 400;
        color: #18191D;
    }
    
    .subtitle1{
        font-size: 10% ;
        font-weight: 400;
        color: #18191D;
    }
    & > div:not(:first-child){
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end; 
        padding: 10px 30px;
        border-bottom: solid 0.5px #E7E7E7;
        height: 90px;
    }
    & > div {
        padding: 10px 30px;
        border-bottom: solid 0.5px #E7E7E7;
        height: 90px;
    }
    &:hover{
        & > div{
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02); 
            transition: 0.3s all ease-in-out;
            opacity: 1 ;
        }
        cursor: pointer;
         background: whitesmoke;
         transition: 0.3s all ease-in-out;
    }
}

.userDetail{
    padding-top: 10px;
}

.valueStyle{
    font-size: 0.8rem;
    font-weight: 600;
    color: #18191D;
}


.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.searchContainer{
    width: 250px;
    // height: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 10px;
    .searchInput{
        border: none;
    }
}