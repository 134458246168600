.menuWrapper {
  display: flex;
  height: 100vh;
}

.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImg {
  width: 60px;
  height: 60px;
  // border-radius: 50%;
}
.menuItemImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 3px;
}
.selectedMenu {
  width: 50px;
  height: 50px;
  margin-top: 20px;
  border: 3px solid black;
  border-radius: 50%;
}
.logoutIcon {
  width: 50px;
  height: 50px;
}

// sub menu

.subMenuHeader {
  font-size: 25px;
  font-weight: 700;
}
.subMenuSubHeader {
  font-size: 11px;
  font-weight: 700;
  opacity: 0.5;
}

// New Css

.mainMenu {
  width: 25%;
  background-color: #4b2a91;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logoStyle {
  img {
    width: 50px;
    height: 50px;
  }

  margin-bottom: 20px;
}

.menuItem {
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.subMenu {
  width: 75%;
  // padding-top: 25px;
  padding: 25px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10vh;
}

.subMenuTitle {
  font-size: 35px;
  font-weight: 800;
  margin: 20px 0px;
}
.subMenuItem {
  margin: 30px 0px;
  font-size: 20px;
  font-weight: 400;
}
.loginButton {
  width: 100%;
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid #ebebeb;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signupButton {
  width: 100%;
  height: 64px;
  background-color: #4b2a91;
  color: white;
  font-size: 20px;
  font-weight: 700;
  border: none;
  border-radius: 25px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileTitle {
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
}
.profileMenuWrapper {
  padding: 30px 0px;
  div {
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 400;
  }
}
.statsCardContainer {
  padding: 0px 0px;
  .statCardMobile {
    padding: 10px 10px;
    font-size: 20px;
    font-weight: 400;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    margin-bottom: 20px;
    :nth-child(1) {
      font-weight: 700;
      font-size: 25px;
    }
    :nth-child(2) {
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.buttonGroup {
  position: absolute;
  bottom: 0;
  width: 100%;

  padding: 20px 0px;
}
.primaryButton {
  width: 250px;
  height: 50px;
  background: #4b2a91;
  border-radius: 35px;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  }
}
.secondaryButton {
  width: 250px;
  height: 50px;
  background: white;
  border-radius: 35px;
  border: 1px solid #e7e7e7;
  color: #212529;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  }
}
