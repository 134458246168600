.campaigns {
  height: 100%;
  width: 37%;
  padding: 0px 30px;
  padding-top: 60px;
  max-height: 100%;
  overflow-y: scroll;

  & > .title {
    font-size: 35px;
    font-weight: 800;
    padding-bottom: 30px;
  }
}

.eachCampaigns {
  margin-bottom: 60px;

  & > .imgDiv {
    width: 100%;
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;
    margin-bottom: 1.75rem;
    min-height: 200px;

    & > img {
      width: 100%;
      object-fit: contain;
    }
  }

  & > .title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 1.75rem;
  }

  & > .para {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 1.75rem;
  }

  & > .btnsContainer {
    font-weight: 600;
    font-size: 15px;
    color: white;
    margin-bottom: 1.75rem;

    & > div {
      padding: 1.15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0.75rem;
      transition: all ease-in 0.2s;

      &:hover {
        // background: white;
        // color: #212529;
        // border: 1px solid #212529;
        scale: 1.025;
      }
    }

    & > :nth-child(1) {
      background: #4b2a91;
      border-radius: 45px;
    }
    & > :nth-child(2) {
      background: #212529;
      border-radius: 45px;
    }
  }
}
