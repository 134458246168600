.eachQuesAnswer {
  border: 0.5px solid #e7e7e7;
  border-radius: 35px;
  padding: 40px 1.5rem;

  & > .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  & > .options {
    font-size: 15px;

    & > div {
      padding: 1rem 1.5rem;
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 35px;
      margin-top: 1rem;
      cursor: pointer;
    }
  }

  & > .resultDiv {
    position: fixed;
    inset: 0;
    z-index: 1;

    & > .overlay {
      position: absolute;
      inset: 0;
      z-index: 1;
      background: #00000080;
    }

    & > .result {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      background: #ffffff;
      border: 0.5px solid #ebebeb;
      border-radius: 35px;
      padding: 3rem;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      width: 50%;
      max-width: 500px;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > .title {
        font-size: 2rem;
        font-weight: 600;
      }
      & > .response {
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
      }

      & > .balances {
        flex-direction: column;
        gap: 1rem;

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.25rem 2rem;
          background: #ffffff;
          border: 0.5px solid #e7e7e7;
          border-radius: 35px;
          font-weight: 500;
          font-size: 0.9rem;
          width: 100%;
        }
      }

      & > .btnDiv {
        gap: 1rem;

        & > div {
          white-space: nowrap;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem 1.5rem;
          opacity: 0.9;
          border-radius: 35px;
          color: white;
          font-weight: 600;
          font-size: 1rem;
          cursor: pointer;
          width: 70%;
        }

        & > .purpleBtn {
          background: #4b2a91;
        }

        & > .blackBtn {
          background: #212529;
        }
      }
    }
  }
}
