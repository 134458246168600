.navContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  div {
    display: flex;
    align-items: center;
    height: 90px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    // &:hover{
    //     background-color: #f5f5f5;
    // }
  }
  a {
    display: flex;
    align-items: center;
    height: 90px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    // &:hover{
    //     background-color: #f5f5f5;
    // }
  }
  div:nth-child(1) {
    justify-content: flex-start;
    padding-left: 20px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  div:nth-child(2) {
    justify-content: center;
  }
  a:nth-child(2) {
    justify-content: center;
  }
  div:nth-child(3) {
    justify-content: flex-end;
    padding-right: 20px;
  }
}
