.storyWrapper{
 position: fixed;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 background-color: black;
 display: flex;
 justify-content: center;
 align-items: center;
background-color: white;
 filter: blur(70px);
  -webkit-filter: blur(70px);
  
  /* Full height */
  height: 100%; 
  
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}


.storySection{

   position: fixed;
   top: 10vh;


// border-radius: 90px;

   height: 80vh;
   width: 400px;
  //  background-color: whitesmoke;
   border-radius: 20px;
   // border: 1px solid white;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 2;
}



.progressDiv {
   position: absolute;
   top: 0;
   margin:10px;
   // background-color: rgb(233, 233, 233);
   background-color: white;
   // opacity: 0.5;
   background-color: lightgray;
}

.progress {
   // background-color: rgb(62, 122, 235);
   background-color: white;
   height: 3px;
   border-radius: 1rem;
   transition: 4s ease;
   transition-delay: 0.5s;
 }

 .contentStyle{
   background: #333;
	width: 100%;
	padding: 20;
	color: white;
	height: 100;
 }

 .viewButton{
   position: absolute;
   right:0;
   top:0;
   z-index: 9999;
   background-color: white;
   color: black;
   border: none;
   padding: 7px 20px;
   border-radius: 25px;
   margin: 10px;
   margin-top: 60px;
   font-size: 18px;
   font-weight: 800;
   cursor: pointer;
 }

 @media screen and (max-width: 600px) {
   .storySection{

   position: fixed;
   top: 0;
   left: 0;
   // right: 0;
   // bottom: 0;
  
   height: 100%;
   width: 100%;
// border-radius: 90px;

   // height: 80vh;
   // width: 400px;
  //  background-color: whitesmoke;
   border-radius: 20px;
   // border: 1px solid white;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 2;
}

.viewButton{
   position: absolute;
   right:0;
   top:0;
   z-index: 9999;
   background-color: white;
   color: black;
   border: none;
   padding: 7px 20px;
   border-radius: 25px;
   margin: 10px;
   margin-top: 20px;
   font-size: 18px;
   font-weight: 800;
   cursor: pointer;
 }

 }