.articleWrapper {
  min-height: 50vh;
  padding: 0px 100px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  background-color: rgba(237, 237, 237, 0.37);
  .articleSection {
    background-color: white;
    margin: 5vh 0px;
    border-radius: 20px;
    padding: 50px 48px;
    .articleTitle {
      font-size: 30px;
      font-weight: 700;
    }
    .articleSubTitle {
      padding: 45px 0px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }
    .articleMedia {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
    .articleContent {
      font-weight: 400;
      font-size: 18px;
      line-height: 35px;
      padding: 45px 0px;
    }
  }
  .otherSection {
    background-color: white;
    margin: 5vh 0px;
    border-radius: 20px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    gap: 20px;
    // align-items: center;
    // position: sticky;
    // top: calc(155px + 5vh);
    // bottom: 0;
    // height: calc(95vh - 155px);
    height: max-content;
  }
}

.pillWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 39px;
  // margin-right: ;
}
.searchPill {
  border-radius: 35px;
  padding: 12px 43px;
  font-size: 16px;
  font-weight: 700;
  background-color: white;
  margin-bottom: 22px;
  margin-right: 27px;
  border: 1px solid #e7e7e7;
  &:hover {
    cursor: pointer;
    background-color: #4b2a91;
    color: white;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
  }
}

.relatedWrapper {
  .relatedTitle {
    font-size: 30px;
    font-weight: 700;
    padding-top: 50px;
  }
  .relatedArticles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 48px;
    .individualArticle {
      width: 45%;
      div:nth-child(1) {
        // background-color: red;
        height: 20vh;
        // border: 1px solid #e7e7e7;
        border-radius: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }
    }
  }
}

.mobileArticle {
//   border-radius: 35px 35px 0px 0px;
//   margin-top: -100px;
  padding: 20px 12px;
  background-color: white;
  z-index: 1;
}
.articleMobileTitle {
  font-weight: 600;
  font-size: 19px;
  line-height: 35px;
}

.metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  .author {
    display: flex;
    align-items: center;
    img {
      height: 28px;
      width: 28px;
      border-radius: 50%;
    }
    // div {
    //   padding-left: 10px;
    //   font-weight: 800;
    //   font-size: 14px;
    // }
    .authorData{
        padding-left: 10px;
        font-weight: 800;
        font-size: 14px;
        .name{
            font-weight: 600;
            font-size: 20px;
        }
        .timestamp{
            font-weight: 400;
            font-size: 13px;
        }
       }
  }
  .timestamp {
    font-weight: 800;
    font-size: 14px;
  }
}

.articleContent{
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    // padding: 45px 0px;
}
.categoryWrapper{
    display: flex;
    align-items: center;
    padding: 40px 0px;
}
.category{
    padding: 10px 30px;
    border: 1px solid #EBEBEB;
    border-radius: 35px;
    margin-right: 20px;
    font-size: 13px;
    font-weight: 500;
}

.floatingBar {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
  }

  & > :nth-child(1) {
    cursor: pointer;
    border-right: 1px solid #ebebeb;
  }

  & > :nth-child(2) {
    width: 70%;
    & > div {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
      font-size: 1rem;
      color: #212529;
      height: 100%;
    }

    & > :nth-child(1) {
      border-right: 1px solid #ebebeb;
    }
  }

  & > :nth-child(3) {
    cursor: pointer;
    border-left: 1px solid #ebebeb;

    & > img {
      rotate: 180deg;
    }
  }

  & > :last-child {
    position: fixed;
    inset: 0;
    background: rgb(0, 0, 0, 0.5);
    z-index: 2;
  }
}
