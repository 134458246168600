.container{
    padding-left: 140px;
    padding-right: 80px;
    padding-top: 30px;
    padding-bottom: 58px;
    // padding: 58px 140px;
}
.headerContainer{
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding-bottom: 28px;
    // border-bottom: 0.5px solid #e7e7e7;
}
.header{
    font-weight: 600;
    font-size: 35px;
}


.tabContainer{
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 30px;
    border-bottom: 0.5px solid #e7e7e7;
    .tab{
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        padding-top: 31px;
        padding-right: 70px;
        border-radius: 10px;
        cursor: pointer;
        opacity: 0.25;
        &.active{
            background-color: #f5f5f5;
        }
      
    }
}
.savedItemGrid{
    display: grid;
    grid-template-columns: 65% 35%;
    gap: 20px;
    .cardStyle{  
        border-radius: 15px;
        padding: 10px;
        border: 1px solid #e7e7e7;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .cardLable{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            padding: 10px 0px;
        }
        .cardData{
            font-weight: 800;
            font-size: 25px;
            line-height: 35px;
            padding: 10px 0px;
        }
    }
}

.newsCard{
    height: 230px;
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 60% 40%;
    &:hover{
        cursor: pointer;
        color: #4B2A91;
        font-weight: 800;
        box-shadow: 0px 0px 10px 0px #e7e7e7;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border-color: #4B2A91;
        border-width: 1.5px;
        img{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transition: all 0.3s ease-in-out;
        }
    }
}