.articleCard{
    margin: 40px 0px;
    border: 0.5px solid #EBEBEB;
    border-width: 0.5px 0px 0.5px 0px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .title{
        font-weight: 600;
        font-size: 19px;
        line-height: 30px;
        padding-top: 31px;
        padding-bottom: 10px;
    }
    .description{
        font-weight: 300;
        font-size: 12px;
        line-height: 22px;
        overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 4; 
   -webkit-box-orient: vertical;

    }
    .metadata{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 0px;
        .author{
            display: flex;
            align-items: center;
           img{
            height: 28px;
            width: 28px;
            border-radius: 50%;
           }
           div{
            padding-left: 10px;
            font-weight: 600;
            font-size: 17px;
           }
        }
        .timestamp{
            font-weight: 700;
            font-size: 15px;
            opacity: 0.5;
        }
    }
    


}


.categoryWrapper{
    display: flex;
    align-items: center;
    padding-top: 20px;
}
.category{
    padding: 10px 30px;
    border: 1px solid #EBEBEB;
    border-radius: 35px;
    margin-right: 20px;
    font-size: 13px;
    font-weight: 500;
}