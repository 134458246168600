@import "../../assets/scss/colors";
@import "../../assets/scss/mixin";


.signInpage {
  height: 0;
  flex: 1;
  display: flex;
  .imgBg {
    height: 100%;
    width: auto;
    min-width: 50%;
    object-fit: cover;
  }
  .sideBar {
    flex: 1;
    border-left: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    position: relative;
    .logo {
      width: 60%;
      margin-bottom: 70px;
    }
    .btnLogin,
    .btnRegister {
      width: 60%;
      height: 70px;
      border-radius: 15px;
      margin: 10px 0;
      background-color: $app-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      color: white;
      @include btn-animate(white);
    }
    .btnLogin {
      border: 1px solid $border-color;
      background: $app-color;
      color: white;
    }
    .bare {
      width: 60%;
      .btnLogin {
        margin-top: 30px;
        width: 100%;
      }
    }
    .sidebarFooter {
      width: 100%;
      height: 70px;
      background: $app-color;
      color: white;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include btn-animate(white);
      position: absolute;
      svg {
        height: 40%;
      }
    }
  }
}

