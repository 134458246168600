.blogSideMenu {
  color: #212529;
  height: 100%;

  & > .filterType {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    // border-bottom: 1px solid #e7e7e7;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 0.75rem 0;
      cursor: pointer;
      transition: all ease-in 0.2s;

      &:hover {
        background: rgb(229, 229, 229, 0.5);
        border-radius: 5px;
        // align-items: flex-end;
      }
    }
  }

  & > .card {
    display: flex;
    padding: 1rem;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
    gap: 0.75rem;
    margin: 1.5rem 0;
    position: relative;
    // margin-bottom: 1.5rem;

    & > .profileImg {
      min-height: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        height: 40px;
        width: 40px;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    & > .details {
      & > :nth-child(1) {
        font-size: 17px;
        font-weight: 600;
      }
      & > :nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
        transition: all ease-in 0.1s;

        &:hover {
          font-weight: 600;
        }
      }
    }
    & > .socialHandles {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      & > svg {
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: all ease-in 0.2s;

        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  & > .contentDiv {
    // overflow-y: scroll;
    // border: 1px solid red;
    // height: -webkit-fill-height-available;
    height: calc(100% - 121.4px - 3rem);
  }
}

.eachCardContentDiv {
  border-bottom: 1px solid #e7e7e7;
  padding: 1rem 0;
  display: flex;
  // justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    transform: translateX(15px);
    // border: 1px solid #e7e7e7;
    // border-radius: 5px;
  }

  & > .imgContainer {
    min-width: 40%;
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    & > img {
      height: 100%;
      width: 100%;
      max-height: 115px;
      //   object-fit: contain;
      border-radius: 10px;
    }
  }

  & > .contentContainer {
    & > :nth-child(1) {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.5;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & > :nth-child(2) {
      margin-top: 10px;
      font-size: 11px;
      font-weight: 400;
    }
  }
}
