.section1 {
  height: 40px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  padding-top: 10px;
  overflow-x: scroll;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.noScroll {
  overflow: hidden;
}

.section2 {
  height: 67px;
  padding: 0px 100px;
  border-bottom: 1px solid #e7e7e7;
  display: grid;
  grid-template-columns: 0.4fr 0.4fr 0.4fr;
  // @media screen and (min-width: 1600px) {
  //     grid-template-columns: 0.8fr 0.4fr;

  // }
  div {
    display: flex;
    align-items: center;
    justify-content: start;
    text-shadow: 14px;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }

  .searchBox {
    position: relative;

    .searchIco {
      position: absolute;
      right: 25px;
      top: 27px;
      opacity: 0.25;
    }

    .searchBar {
      border: 0.5px solid #e7e7e7;
      border-radius: 25px;
      color: #212529;
      opacity: 1;
      padding: 10px 20px;
      font-weight: 700;
      width: 100%;

      &::placeholder {
        font-weight: 700;
        font-size: 14px;
        color: #212529;
        opacity: 0.25;
      }
    }

    .searchData {
      position: absolute;
      width: 100%;
      min-width: 500px;
      max-height: 600px;
      background: white;
      z-index: 10;
      top: 10px;
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 10px;
      align-items: start;
      padding: 10px;
      flex-direction: column;

      .searchBar {
        border-radius: 25px;

        &:focus {
          border: 1px solid #4b2a91;
        }
      }

      .resultsType {
        font-weight: 600;
        font-size: 13px;
        color: #212529;
        margin-top: 20px;
      }

      .dispType {
        display: flex;
      }

      .dispText {
        font-weight: 400;
        font-size: 14px;
        color: #212529;
        margin-top: 10px;
      }

      .selectedText {
        font-weight: 600;
        font-size: 14px;
        color: #4b2a91;
        background: rgba(75, 42, 145, 0.05);
        border-radius: 25px;
        padding: 5px 25px;
      }
    }

    .modal {
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      background-color: rgba(0, 0, 0, 0.7);
    }

    //   .modalMain {
    //     position: relative;
    //     background-color: #fff;
    //     margin: 10% auto;
    //     padding: 20px;
    //     border-radius: 4px;
    //     width: 50%;
    //     max-width: 500px;
    //   }

    .display-block {
      display: block;
    }

    .display-none {
      display: none;
    }
  }

  // :nth-child(1){
  //     display: flex;
  //     align-items: center;
  // }

  .logoimg {
    width: 45px;
    height: 39px;
    // transform: rotate(180deg);
  }

  .navSty {
    margin-left: 20px;
    // transition: all ease-in 0.4s;

    &:hover {
      padding: 0.3rem 0;
      border-bottom: 2px solid #4b2a91;
    }
  }

  .selectedNav {
    background-color: rgba(75, 42, 145, 0.05);
    color: #4b2a91;
    font-weight: 700;
    border-radius: 25px;
    width: auto;
    height: 36px;
    padding: 0px 30px;
  }

  .selectedNav1 {
    background-color: #4b2a91;
    color: white;
    font-weight: 700;
    border-radius: 20px 20px 0px 0px;
    width: auto;
  }

  .loginBtn {
    background: #ffffff;
    opacity: 0.9;
    border: 1px solid #e7e7e7;
    border-radius: 25px;

    .loginBtnText {
      font-weight: 700;
      font-size: 12px;
      color: #212529;
      padding: 10px 41px;
    }
  }

  .startBtn {
    background: #4b2a91;
    opacity: 0.9;
    border-radius: 25px;

    .startBtnText {
      font-weight: 700;
      font-size: 12px;
      color: #ffffff;
      padding: 10px 26px;
    }
  }

  .right {
    display: flex;
    justify-content: end;
    align-items: center;
    text-shadow: 14px;
    font-weight: 500;
    // .iconBox{

    //     padding: 27px 30px;
    //     border-left: 1px solid #e7e7e7;
    //     // border-right: 1px solid #e7e7e7;

    // }
    // .getStarted{
    //     padding: 27px 47px;
    //     border-left: 1px solid #e7e7e7;
    //     color: #212529;
    //     font-weight: 600;
    //     font-size: 17px;
    //     &:hover{
    //         cursor: pointer;
    //         background-color: #4B2A91;
    //         color: white;
    //         transition: all 0.3s ease-in-out;
    //     }
    // }
  }
}

.section3 {
  padding: 0px 100px;
  display: flex;
  background-color: #4b2a91;
  // justify-content: space-between;
  align-items: center;

  // border-bottom: 1px solid #e7e7e7;
  div {
    // width:200px;
    padding: 12px 0px;
    text-align: center;
    padding-right: 50px;
    color: white;
    cursor: pointer;
  }

  .selectedSubNav {
    font-weight: 700;
  }
}

.popup {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 100px;
  margin-top: 100px;
  width: 172px;
  height: 207px;
  background-color: white;
  border: 0.5px solid #e5e5e5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.storeWrapper {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0px 20px;
  padding-top: 35px;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
}

.textSection {
  padding-left: 5px;

  :nth-child(1) {
    font-size: 10px;
    font-weight: 500;
    color: #212529;
  }

  :nth-child(2) {
    font-size: 15px;
    font-weight: 700;
    color: #212529;
    margin-top: -2.6px;
  }
}

.loginButton {
  background-color: #4b2a91;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  font-weight: 700;
  font-size: 14px;
  color: white;

  &:hover {
    cursor: pointer;
    background-color: #2f195c;
    transition: all 0.3s ease-in-out;

    span {
      transform: scale(1.1);
      transition: all 0.1s ease-in-out;
    }
  }
}

@media only screen and (max-width: 700px) {
  .mobileNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 7%;
    height: 77px;
    border-bottom: 10px solid #4B2A91;
    padding-right: 7%;
  }
  .section1 {
    display: none;
  }
  .section3 {
    display: none;
  }
  .section2 {
    display: none;
  }
}
