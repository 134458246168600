.container{
    display: grid;
    grid-template-columns: 1fr 4fr;
}
.leftSide{
padding: 33px 23px;
}
.searchTitle{
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    padding-bottom: 16px;
}
.searchBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    margin-bottom: 20px;
    border: 0.5px solid #E7E7E7;
    padding: 16px 24px;
    border-radius: 5px;
    input{
        width: 80%;
        border: none;
        outline: none;
        font-weight: 700;
    }
}


.filterBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px;
    padding-right: 20px;
    border-bottom: 0.5px solid #E7E7E7;
}
.filterTitle{
    font-weight: 700;
    font-size: 20px;
    // line-height: 35px;
    // padding-bottom: 16px;
}







.rightSide{
   background-color: #F5F5F5; 
   padding: 67px 56px;
   overflow-y: scroll;
}
.cardStyle{
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    // height: 298px;
    &:hover{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
.cardImage{
    width: 140px;
    height: 140px;
    // object-fit: cover;
    padding: 25px;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.topRow{
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 20px;
}
.titleContainer{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.cardTitle{
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    padding-bottom: 30px;
}
.cardDescription{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}
.bottomRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 40px 0px;
}
.itemTitle{
    font-weight: 300;
    font-size: 12px;
    // line-height: 23px;
    padding-bottom: 13px;
}
.itemValue{
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
}
.learnMoreButton{
    // width: 133px;
    font-weight: 700;
    font-size: 14px;
    padding: 15px 18px;
    background-color: #4B2A91;
    color: #FFFFFF;
    border-radius: 15px;
}

