.articleStickyFooter {
  position: sticky;
  background: #4b2a91;
  height: 62px;
  color: white;
  bottom: 0;
  z-index: 1;
  padding: 0rem 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding-left: 2rem;
    width: -webkit-fill-available;

    & > :nth-child(1) {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
    }
    & > :nth-child(2) {
      display: flex;
      align-items: center;
      text-align: center;
      background: #ffffff;
      border-radius: 25px;
      color: #4b2a91;
      padding: 0.5rem 2rem;
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;
      white-space: nowrap;
      transition: all ease-in 0.4s;

      &:hover {
        scale: 1.025;
      }
    }
  }

  & > :nth-child(1) {
    border-right: 1px solid #ffffff;
    padding-right: 2rem;
    padding-left: 0;
  }

  & > :nth-child(2) {
    justify-content: flex-start;
  }
}
