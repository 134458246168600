.searchWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 100%;
    height: 100%;
    // background-color: #f5f5f5;
    border-bottom: 1px solid #E7E7E7;
    height: 67px;
        .inputStyle{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            // background-color: #f5f5f5;
            font-weight: 700;
            font-size: 18px;
            padding: 0 10px;
            margin: 0px 10px;
        }
        .filterButton{
            background: #4B2A91;
            color: white;
            border-radius: 25px;
            padding: 10px 29px;
            font-weight: 700;
            font-size: 15px;
        }
    
}
.cardWrapper{
    margin: 12px;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    padding: 19px 22px;
    .topRow{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .cardImage{
        width: 80px;
        height: 80px;
        // object-fit: cover;
        padding: 15px;
        border: 0.5px solid #E7E7E7;
        border-radius: 15px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .industryLable{
        font-weight: 300;
        font-size: 12px;
    }
    .industryValue{
        font-weight: 700;
        font-size: 19px;
    }
    .bottomRow{
        padding: 30px 0px;
        .cardTitle{
            font-weight: 700;
            font-size: 20px;
            padding-bottom: 25px;
        }
        .cardDescription{
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
        }
        .actionWrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 25px;
            .learnMoreButton{
                background-color: #4B2A91;
                color: white;
                font-weight: 700;
                font-size: 14px;
                padding: 10px 18px;
                border-radius: 15px;

            }
            .websiteWrapper{
                display: flex;
                .website{
                    font-weight: 500;
                    font-size: 15px;
                    padding-left: 8px;
                }
            }
        }
    }
}