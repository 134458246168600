.mainLayout {
  display: flex;
  flex-direction: row;
  // height: 100vh;
  // width: 100vw;
  // background-color: #f5f5f5;
  // overflow: hidden;
}

.loggedInContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  background-color: white;
  border: 1px solid #efefef;
  height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  display: none;
}
.profilePic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #efefef;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
}

.logout {
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }
}

.collapseButton {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0.5px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 68px;
  top: 118px;
  background: white;
  cursor: pointer;
  z-index: 3;
  display: none;
}

.sideDraw {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1;

  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  // padding: 20px 0px;
  transition: all 0.3s ease-in-out;
  display: grid;
  grid-template-columns: 280px 7fr;

  .leftSide {
    width: 280px;
    background-color: white;
    border: 1px solid #efefef;
    height: 100vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    padding: 35px 15px;
    color: #212529;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .rightSide {
    background-color: black;
    opacity: 0.6;
    height: 100vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
  }
}
.darkBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.nameStyle {
  font-weight: 700;
  font-size: 26px;
  line-height: 23px;
}
.emailStyle {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  padding-top: 8px;
}

.menuWrapper {
  border: 1px solid #e7e7e7;
  border-width: 1px 0px 1px 0px;
  padding-top: 26px;
  margin-top: 17px;
}
.menuItem {
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  }
}
.numberStyle {
  font-weight: 700;
  font-size: 2rem;
  line-height: 23px;
  padding-left: 10px;
  padding-bottom: 20px;
}
.numberLableStyle {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding-left: 10px;
  color: #212529;
}
.buttonGroup {
  // position: absolute;
  // bottom: 0;
  // width: 100%;

  padding: 20px 0px;
}
.primaryButton {
  width: 250px;
  height: 50px;
  background: #4b2a91;
  border-radius: 35px;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  }
}
.secondaryButton {
  width: 250px;
  height: 50px;
  background: white;
  border-radius: 35px;
  border: 1px solid #e7e7e7;
  color: #212529;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  }
}

.statWrapper {
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  }
  .darkBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;
  }

  .overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  .nameStyle {
    font-weight: 700;
    font-size: 26px;
    line-height: 23px;
  }
  .emailStyle {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    padding-top: 8px;
  }

  .menuWrapper {
    border: 1px solid #e7e7e7;
    border-width: 1px 0px 1px 0px;
    padding-top: 26px;
    margin-top: 17px;
  }
  .menuItem {
    padding-bottom: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    &:hover {
      cursor: pointer;
      font-weight: bold;
      transform: scale(1.06);
      transition: all 0.2s ease-in-out;
    }
  }
  .numberStyle {
    font-weight: 700;
    font-size: 2rem;
    line-height: 23px;
    padding-left: 10px;
    padding-bottom: 20px;
  }
  .numberLableStyle {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    padding-left: 10px;
    color: #1f204f;
  }
  .buttonGroup {
    // position: absolute;
    // bottom: 0;
    // width: 100%;

    padding: 20px 0px;
  }
  .primaryButton {
    width: 250px;
    height: 50px;
    background: #4b2a91;
    border-radius: 35px;
    border: none;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    &:hover {
      cursor: pointer;
      transform: scale(1.06);
      transition: all 0.2s ease-in-out;
    }
  }
  .secondaryButton {
    width: 250px;
    height: 50px;
    background: white;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    color: #1f204f;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      transform: scale(1.06);
      transition: all 0.2s ease-in-out;
    }
  }

  .statWrapper {
    &:hover {
      cursor: pointer;
      transform: scale(1.06);
      transition: all 0.2s ease-in-out;
    }
  }

  @media only screen and (max-width: 700px) {
    .loggedInContainer {
      display: none;
      display: none;
    }
  }
}
