@import "src/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

// @import "src/variables.scss";
.App {
  font-family: "Montserrat";
  position: relative;
  // height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  // display: flex;
  // overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.darkBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.3;
  z-index: 9999;
  display: none;
}

p {
  margin: 0;
  padding: 0;
}

.App ::-webkit-scrollbar {
  display: none;
}

button:focus,
input:focus {
  outline-color: transparent;
}
