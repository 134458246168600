.articleSearch {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 300px;
  margin-top: 10px;
  position: relative;
  width: 100%;

  .articleBox {
    // height: 200px;
    border: 1px solid black;
    display: flex;
    justify-content: space-between !important;
    padding: 20px;
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;
    margin-top: 20px;
    width: 100%;

    .articleLeftData {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 60%;

      .searchArticleText {
        font-weight: 700;
        font-size: 17px;
        color: #212529;
      }

      .searchArticleSubText {
        font-weight: 300;
        font-size: 12px;
        color: #212529;
      }
    }

    .articleRightData {
      .imageSize {
        width: 150px;
        border-radius: 15px;
      }
    }
  }
}

.bottomBtn {
  justify-content: start;
  margin-top: 70px;
  .viewAll {
    width: 95%;
    padding: 14px;
    color: white;
    background: #4b2a91;
    margin-top: 10px;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 12px;
    margin-bottom: 10px;
  }
}
