.articleCard{
    margin: 40px 0px;
    border: 0.5px solid #EBEBEB;
    border-width: 0.5px 0px 0.5px 0px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .title{
        font-weight: 800;
        font-size: 19px;
        line-height: 30px;
        padding-top: 31px;
        padding-bottom: 26px;
    }
    .description{
        font-weight: 300;
        font-size: 12px;
        line-height: 22px;

    }
    .metadata{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 0px;
        .author{
            display: flex;
            align-items: center;
           img{
            height: 28px;
            width: 28px;
            border-radius: 50%;
           }
           div{
            padding-left: 10px;
            font-weight: 800;
            font-size: 14px;
           }
        }
        .timestamp{
            font-weight: 800;
            font-size: 14px;
        }
    }
    


}